<template>
  <div id="app">
    <!-- <div class="preloader" id="preloader">
      <div class="spinner-grow text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div> -->
    <!-- Header Area-->
    <header class="header-area header2">
      <div class="container">
        <div class="classy-nav-container breakpoint-off">
          <nav
            class="classy-navbar navbar2 justify-content-between"
            id="saasboxNav"
          >
            <!-- Logo-->
            <router-link class="nav-brand mr-5" to="/">
              <img src="@/assets/jcargo_white.png" width="100px" alt="" />
            </router-link>

            <!-- Navbar Toggler-->
            <div class="classy-navbar-toggler">
              <span class="navbarToggler"
                ><span></span><span></span><span></span><span></span
              ></span>
            </div>
            <!-- Menu-->
            <div class="classy-menu">
              <!-- close btn-->
              <div class="classycloseIcon">
                <div class="cross-wrap">
                  <span class="top"></span><span class="bottom"></span>
                </div>
              </div>
              <!-- Nav Start-->
              <div class="classynav">
                <ul id="corenav">
                  <li>
                    <router-link to="/calculation"
                      >Тээврийн тооцоолуур</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/help">Тусламж</router-link>
                  </li>
                  <li>
                    <router-link to="/training">Сургалт</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">Холбоо барих</router-link>
                  </li>
                  <li v-if="!loggedIn">
                    <router-link to="/login">Нэвтрэх</router-link>
                    <!-- <a href="contact.html">Нэвтрэх</a> -->
                  </li>
                  <li v-if="loggedIn">
                    <router-link to="/profile">Профайл</router-link>
                    <!-- <a href="contact.html">Нэвтрэх</a> -->
                  </li>
                </ul>
                <!-- Login Button-->
                <div class="login-btn-area ml-4 mt-4 mt-lg-0" v-if="!loggedIn">
                  <router-link class="btn btn-red" to="/register"
                    >Бүртгүүлэх</router-link
                  >
                  <!-- <a class="btn saasbox-btn btn-sm-j" href="#">Бүртгүүлэх</a> -->
                </div>
                <div class="login-btn-area ml-4 mt-4 mt-lg-0" v-else>
                  <a class="btn btn-red" href @click.prevent="logout">Гарах</a>
                  <!-- <a class="btn saasbox-btn btn-sm-j" href="#">Бүртгүүлэх</a> -->
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
    <footer class="footer-area bg-black section-padding-120">
      <div class="container">
        <div class="row justify-content-between">
          <!-- Footer Widget Area-->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="footer-widget-area mb-70">
              <router-link class="d-block" to="/">
                <img src="@/assets/jcargo_white.png" alt="" />
              </router-link>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="footer-widget-area mb-70">
              <h5 class="widget-title text-red">Манай хаяг</h5>
              <ul>
                <li>
                  <span style="font-size: 15px">
                    📍 "БЗД" дэх салбараас бараа авах бол БЗД 14-р хороо Шөнийн
                    зах 1 давхарт X12-р павилион
                    <br />
                    Утас: 80018931, 91260413
                  </span>
                  <span style="font-size: 15px; margin-top: 10px">
                    📍 "ХУД" 3-хороо Монгол дээд сургууль 2 давхарт 24 тоот
                    <br />
                    Утас: 80070726
                  </span>
                  <span style="font-size: 15px; margin-top: 10px">
                    ⏰ ЦАГИЙН ХУВААРЬ: Даваа-Баасан гаригт 11:00-20:00 Бямба-Ням
                    гаригт 11:00-19:00 Пүрэв гаригт амарна. Цайны цаг
                    14:00-15:00
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <!-- Footer Widget Area-->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <div class="footer-widget-area mb-70">
              <h5 class="widget-title text-red">Үйлчилгээний төв</h5>
              <ul>
                <li>
                  <router-link to="/terms">Үйлчилгээний нөхцөл</router-link>
                </li>
                <li @click="aguulah()">
                  <a style="cursor: pointer">Манай агуулах хаяг</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <!-- Copywrite Text-->
            <div class="footer--content-text d-flex justify-content-between">
              <p class="mb-0">©2020 jcargo.mn</p>
              <p class="mb-0">Холбоо барих: (+976) 91260413</p>
              <p class="mb-0">
                <a
                  href="https://www.facebook.com/Jcargo.mn/"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Facbook"
                  style="color: #8480ae"
                  ><i class="fa fa-facebook"></i> Facebook</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <modal
      height="auto"
      :width="600"
      :adaptive="true"
      styles="border: 1px solid #fff; border-radius: 16px; padding: 16px; background: #fff;"
      name="warning"
    >
      <div @click="$modal.hide('warning')" class="close-button">❌</div>
      <img :src="apiStorageUrl + announecement.image" alt="" />
    </modal>
    <modal
      height="auto"
      :width="800"
      :adaptive="true"
      styles="border: 1px solid #fff; border-radius: 16px; padding: 16px; background: #fff;"
      name="aguulah"
    >
      <div class="aguulah-container">
        <div class="text-center size-sm">Эрээний агуулахын хаяг</div>
        <div class="row mt-2">
          <div class="col-4">收件人(Хүлээн авагч)：</div>
          <div class="col-8">
            Та өөрийн нэр, монгол дахь утасны дугаарыг захиалга хийх сайтын
            хүлээн авагч хэсэгт бөглөнө.
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">所在地区(Бүс нутаг)：</div>
          <div class="col-8">
            <div class="d-flex justify-content-between">
              <div id="region">内蒙古自治区 锡林郭勒盟 二连浩特市</div>
              <div @click="copy('region')" class="copy-button">COPY</div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">街道地址(Хаяг)：</div>
          <div class="col-8">
            <div class="d-flex justify-content-between">
              <div id="address">
                二连浩特市社区建设管理局 花园小区街面楼1106号 (Улаанбаатарт
                хүлээн авах хүний дугаар)
              </div>
              <div @click="copy('address')" class="copy-button">COPY</div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">邮编(Зип код)：</div>
          <div class="col-8">
            <div class="d-flex justify-content-between">
              <div id="zip">011100</div>
              <div @click="copy('zip')" class="copy-button">COPY</div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">电话 (Утасны дугаар)：</div>
          <div class="col-8">
            <div class="d-flex justify-content-between">
              <div id="phone">15547933638</div>
              <div @click="copy('phone')" class="copy-button">COPY</div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Api from "./models/api";
import cargoService from "./services/cargo.service";

export default {
  name: "App",
  data() {
    return {
      announecement: {},
      apiStorageUrl: Api.storageUrl,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    // this.warning();
    this.getAnnouncement();
  },
  methods: {
    warning() {
      this.$modal.show("warning");
    },
    aguulah() {
      this.$modal.show("aguulah");
    },
    copy(containerid) {
      var select = document.getElementById(containerid);
      var tmp = document.createElement("input");
      tmp.type = "text";
      tmp.value = select.innerText;
      document.body.appendChild(tmp);
      tmp.select();
      document.execCommand("Copy");
      document.body.removeChild(tmp);
      this.$toasted.show("Амжилттай хууллаа", {
        type: "success",
        duration: 4000,
        keepOnHover: true,
        icon: "exclamation-circle",
        position: "bottom-right",
      });
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },

    getAnnouncement() {
      cargoService
        .getAnnounecement()
        .then((res) => {
          this.announecement = res.data.body;
          if (this.announecement.id) this.$modal.show("warning");
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
